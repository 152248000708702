import { CapacitorFacade } from '../plugins/capacitorPlugin';
import { version as currentVersion } from '../../package.json';

export const getRootLoading = state => state.rootLoading;
export const getLocale = state => state.locale;
export const getDateFormat = state => (state.locale.indexOf('fr') !== -1 ? 'DD/MM/YYYY' : 'MM/DD/YYYY');
export const getDeviceDNA = state => state.deviceDNA;
export const features = state => state.features;
export const alerts = state => state.alerts;
export const showTermConditionModal = state => state.showTermConditionModal;
export const termConditionContent = state => state.termConditionContent;
export const tasks = state => state.tasks;
export const initialized = state => state.initialized;
export const agents = state => state.agents;
export const notifications = state => state.notifications;

export const getAgent = state => agentId => state.agents.find(a => a.agentId === agentId);

export const containsFeature = state => feature => !!state.features.find(f => f.feature === feature && f.flag);

export const langName = (state) => {
  switch (state.locale) {
    case 'en_US':
      return 'English';
    case 'en_CA':
      return 'English CA';
    case 'es_US':
      return 'Spanish';
    case 'fr_CA':
      return 'French';
    default:
      return 'English';
  }
};

export const spanishBanner = state => state.spanishBanner;

export const alertsAndNotifications = (state) => {
  const s = state?.alertsAndNotifications;
  return s;
};

export const ecommAlertAndNotificationContractIds = (state) => {
  const s = state?.alertsAndNotifications?.find(e => e.actionType === 'PAPERLESS_COMM')?.contractIds;
  return s;
};
export const verifyPhonePageToLand = state => state.verifyPhonePageToLand;

export const showVerifyPhoneWrapper = state => state.showVerifyPhoneWrapper;

// Return the list of documents for given policy number
export const getDocuments = state => (policyNumber) => {
  const doc1 = state?.documents?.policyDocuments.filter(a => a.contract === policyNumber);
  const doc2 = state?.documents?.notices.filter(a => a.contract === policyNumber);
  return doc1.concat(doc2);
};

// Return the Full Document for given policy number
export const getFullPolicyDocument = state => (policyNumber) => {
  const doc = state?.documents?.policyDocuments.filter(a => a.contract === policyNumber && a.documentType === 'A');
  return doc ? doc[0] : undefined;
};

export const documents = (state) => {
  const s = state?.documents;
  return s;
};

export const policyDocuments = (state) => {
  const s = state?.documents?.policyDocuments;
  return s;
};

export const noticesAndConfirmations = (state) => {
  const s = state?.documents?.notices;
  return s;
};

export const investmentStatements = (state) => {
  const s = state?.documents?.investmentDocuments;
  return s;
};

export const getShowNavigationConfirmation = state => state.showNavigationConfirmation;

export const getNavigationPathOnUserConfirmation = state => state.navigationPathOnUserConfirmation;

export const getNavigationDialogBody = state => state.navigationDialogBody;

export const isShowGoPaperlessWrapper = state => state.showGoPaperlessWrapper;

export const getGoPaperlessType = state => state.goPaperlessType;
export const isMaintenanceMode = state => state.meta?.isMaintenanceMode;

export const recommendedAppVersion = (state) => {
  const v = state.meta?.recommendedAppVersion[CapacitorFacade.getPlatform()];
  const r = {};
  v.forEach((ver) => {
    const { version, link, critical } = ver;
    if (critical) {
      r.critical = { version, link };
    } else {
      r.optional = { version, link };
    }
  });
  return r;
};

export const currentAppVersion = () => currentVersion;

const compareVersion = (ver1, ver2) => {
  if (typeof ver1 !== 'string') return false;
  if (typeof ver2 !== 'string') return false;
  if (ver1 === ver2) {
    return false;
  }
  const ver1Arr = ver1.split('.');
  const ver2Arr = ver2.split('.');
  const k = Math.min(ver1Arr.length, ver2Arr.length);
  for (let i = 0; i < k; ++i) {
    ver1Arr[i] = parseInt(ver1Arr[i], 10);
    ver2Arr[i] = parseInt(ver2Arr[i], 10);
    if (ver1Arr[i] > ver2Arr[i]) return true;
    if (ver1Arr[i] < ver2Arr[i]) return false;
  }
  if (ver1Arr.length === ver2Arr.length) {
    return true;
  } else if (ver1Arr.length < ver2Arr.length) {
    return false;
  } return true;
};

export const isCriticalUpdateAvailable = (state, getters) => {
  const cv = currentVersion;
  // getting the max version number for critical version
  let v = '';
  if (getters.isIos && Object.keys(state.meta.recommendedAppVersion).length > 0) {
    v = state.meta?.recommendedAppVersion?.ios.filter(item => item.critical === true).reduce((prev, current) => (compareVersion(prev.version, current.version) ? prev : current), 1);
  } else if (getters.isAndroid && Object.keys(state.meta.recommendedAppVersion).length > 0) {
    v = state.meta?.recommendedAppVersion?.android.filter(item => item.critical === true).reduce((prev, current) => (compareVersion(prev.version, current.version) ? prev : current), 1);
  } else {
    return false;
  }
  return compareVersion(v?.version, cv);
};

export const isOptionalUpdateAvailable = (state, getters) => {
  const cv = currentVersion;
  // getting the max version number for non critical version
  let v = '';
  if (getters.isIos && Object.keys(state.meta.recommendedAppVersion).length > 0) {
    v = state.meta?.recommendedAppVersion?.ios.filter(item => item.critical === false).reduce((prev, current) => (compareVersion(prev.version, current.version) ? prev : current), 1);
  } else if (getters.isAndroid && Object.keys(state.meta.recommendedAppVersion).length > 0) {
    v = state.meta?.recommendedAppVersion?.android.filter(item => item.critical === false).reduce((prev, current) => (compareVersion(prev.version, current.version) ? prev : current), 1);
  } else {
    return false;
  }
  return compareVersion(v?.version, cv);
};

export const getAndroidPlayStoreLink = (state) => {
  // const cv = currentVersion;
  // getting the max version number for non critical version
  const v = state.meta?.recommendedAppVersion?.android.reduce((prev, current) => (compareVersion(prev.version, current.version) ? prev : current), 1);
  return v.link || '';
};

export const getiOSStoreLink = (state) => {
  const v = state.meta?.recommendedAppVersion?.ios.reduce((prev, current) => (compareVersion(prev.version, current.version) ? prev : current), 1);
  return v.link || '';
};

export const meta = state => state.meta;

export const isIos = () => CapacitorFacade.getPlatform() === 'ios';

export const isAndroid = () => CapacitorFacade.getPlatform() === 'android';

export const isMobileApp = () => CapacitorFacade.getPlatform() === 'ios' || CapacitorFacade.getPlatform() === 'android';

export const hasTermsOfUseTask = (state, getters) => (!!getters.tasks.find(a => a.name === 'TERMS_OF_USE'));

export const hasPolicyAckTask = (state, getters) => (!!getters.tasks.find(a => a.name === 'POLICY_ACK' && a.shownFlag === false));

export const userSessionMeta = state => (state.userSessionMeta); // TODO: add unit tests
