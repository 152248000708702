<i18n src='./locales.json'></i18n>
<template>
  <v-dialog
    transition="scale-transition"
    origin="center center"
    id="go_paperless_action"
    :value="show"
    :width="$vuetify.breakpoint.xs? '320px':'480px'"
    persistent
  >
    <v-card
      id="otp-card"
    >
      <div :class="$vuetify.breakpoint.xs?'fixed-top-alert-small': 'fixed-top-alert-large'">
        <Alerts context="go_paperless" />
      </div>
      <template v-if="!success">
        <v-card-title class="text-xs-center font-size--24">
          <v-flex
            xs12
            class="font-weight-regular"
          >
            {{ $t('L_go_paperless') }}
          </v-flex>
        </v-card-title>
        <v-card-text
          class="font-weight-regular"
        >
          <v-flex
            xs12
            pa-4
          >
            <span class="font-size--16" v-if="getGoPaperlessType === 'PAPERLESS_COMM'">
              {{ $t('L_go_paperless_top_text') }}
            </span>
            <span class="font-size--16" v-else-if="getGoPaperlessType === 'PAPERLESS_COMM_INVESTMENT'">
              {{ $t('L_go_paperless_top_text_mutual_fund_investment') }}
            </span>
          </v-flex>
        </v-card-text>
        <v-flex
          pl-3
          pr-3
          pt-4
          pb-4
          class="bg-light-grey"
        >
          <v-layout row>
            <v-flex xs1>
              <v-layout
                justify-end
              >
                <v-checkbox
                  v-model="checked"
                  color="primary"
                  class="mt-0 pt-0"
                />
              </v-layout>
            </v-flex>
            <v-flex xs11>
              <strong>{{ $t('L_go_paperless_yes') }}</strong> {{ $t('L_go_paperless_check_text', { email: customerProfile.email }) }}
            </v-flex>
          </v-layout>
        </v-flex>
        <v-card-text :class="$vuetify.breakpoint.xs? 'spacing-bottom-of-doc-pb-small':'spacing-bottom-of-doc-pb-large'">
          <div class="html-content">
            <iframe
              id="edelivery_disclosure_iframe"
              v-if="htmlSrc"
              :src="htmlSrc"
              title="e-delivery disclosure html"
            />
          </div>
        </v-card-text>
      </template>
      <template v-if="success">
        <v-card-title class="text-xs-center font-weight-regular font-size--24 pt-4">
          <v-flex xs12>
            {{ $t('L_go_paperless_thanks') }}
          </v-flex>
        </v-card-title>
        <v-card-text class="text-xs-center font-weight-medium">
          {{ $t('L_go_paperless_chosen') }}
        </v-card-text>
        <v-card-text class="font-weight-regular">
          {{ $t('L_go_paperless_changes') }}
        </v-card-text>
        <v-card-text class="font-weight-regular">
          {{ $t('L_go_paperless_not_all') }}
        </v-card-text>
        <v-card-text>
          <v-btn
            block
            color="primary"
            @click="setShowGoPaperlessWrapper(false); setGoPaperlessType('');"
          >
            {{ $t('L_go_paperless_ok') }}
          </v-btn>
        </v-card-text>
      </template>
      <div v-if="!success" :class="$vuetify.breakpoint.xs? 'setBottomDivCssSmall':'setBottomDivCssLarge'">
        <v-btn
          block
          color="primary"
          :disabled="!checked"
          @click="agree"
        >
          {{ $t('L_go_paperless_i_agree') }}
        </v-btn>
        <v-flex
          mt-4
          class="text-xs-center"
        >
          <a
            href="javascript:void(0)"
            @click="snoozeEcommOption"
            class="text--primary font-size--18"
            v-if="getGoPaperlessType === 'PAPERLESS_COMM'"
          >
            {{ $t('L_go_paperless_skip') }}
          </a>
          <a
            href="javascript:void(0)"
            @click="dismissAlertOption"
            class="text--primary font-size--18"
            v-else-if="getGoPaperlessType === 'PAPERLESS_COMM_INVESTMENT'"
          >
            {{ $t('L_go_paperless_skip') }}
          </a>
        </v-flex>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import bff from '@/shared/api/bff';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import Config from '@/shared/util/config';
import Alerts from '../Alerts.vue';
import { getGoPaperlessType } from '../../store/getters';

export default {
  name: 'GoPaperlessAction',
  components: { Alerts, getGoPaperlessType },
  props: {
    // show: {
    //   type: Boolean,
    //   required: true,
    //   default: false,
    // },
    alertContext: {
      type: String,
      required: false,
      default: 'go_paperless',
    },
    // policies: {
    //   type: Array,
    //   required: true,
    // },
  },
  data: () => ({
    text: undefined,
    checked: false,
    preferences: {},
    success: false,
    processed: false,
    disablePaperlessOkButton: false,
  }),
  computed: {
    ...mapGetters(['alertsAndNotifications']),
    ...mapGetters('customer', ['customerProfile']),
    ...mapGetters({
      locale: 'getLocale',
      isShowGoPaperlessWrapper: 'isShowGoPaperlessWrapper',
      getGoPaperlessType: 'getGoPaperlessType',
      ecommAlertAndNotificationContractIds: 'ecommAlertAndNotificationContractIds',
    }),
    ...mapGetters('policy', {
      policies: 'policies',
    }),
    htmlSrc() {
      if (this.locale === 'en_CA') {
        return `${Config.get('ecomm.en_CA.ecommunication_consent')}`;
      } else if (this.locale === 'fr_CA') {
        return `${Config.get('ecomm.fr_CA.ecommunication_consent')}`;
      }
      return `${Config.get('ecomm.en_US.ecommunication_consent')}`;
    },
    show() {
      const isECOMMEligible = this.$store.getters.containsFeature('ECOMM');
      return (isECOMMEligible && this.isShowGoPaperlessWrapper);
    },
  },
  methods: {
    ...mapActions(['snoozeEcomm', 'dismissAlert']),
    ...mapMutations(['setShowGoPaperlessWrapper', 'setGoPaperlessType']),
    async agree() {
      this.disablePaperlessOkButton = true;
      if (!this.processed) {
        this.processed = true;
        const preferences = await bff.getCommunicationsPreferences()
          .catch((e) => {
            this.$store.commit('addError', { error: e, context: 'go_paperless' });
          });
        preferences.forEach((preference) => {
          this.preferences[preference.policyNumber] = 'DIGITAL';
        });
        try {
          await bff.updatePaperless(this.preferences, this.locale, true);
          this.$emit('success');
          this.$store.commit('addAlert', { type: 'success', details: { message: this.$t('L_go_paperless_success') }, context: 'go_paperless' }, { root: true });
          this.success = true;
        } catch (e) {
          this.disablePaperlessOkButton = false;
          this.success = false;
          this.$store.commit('addError', { error: e, context: 'go_paperless' });
        }
      }
    },
    snoozeEcommOption() {
      if (!this.processed) {
        this.snoozeEcomm().then(() => {
          this.$emit('snooze');
        }).catch((e) => {
          this.$store.commit('addError', { error: e, context: 'go_paperless' });
        }).finally(() => {
          this.setShowGoPaperlessWrapper(false);
          this.setGoPaperlessType('');
        });
        this.processed = true;
      }
      this.setShowGoPaperlessWrapper(false);
      this.setGoPaperlessType('');
    },
    dismissAlertOption() {
      let alertId = '';
      alertId = this.alertsAndNotifications.filter(({ actionType }) => actionType === 'PAPERLESS_COMM_INVESTMENT')[0]?.alertId || '-1';
      if (!this.processed) {
        this.dismissAlert(alertId).then(() => {
          this.$emit('snooze');
        }).catch((e) => {
          this.$store.commit('addError', { error: e, context: 'go_paperless' });
        }).finally(() => {
          this.setShowGoPaperlessWrapper(false);
          this.setGoPaperlessType('');
        });
        this.processed = true;
      }
      this.setShowGoPaperlessWrapper(false);
      this.setGoPaperlessType('');
    },
  },
};
</script>

<style lang="stylus" scoped>
.fixed-top-alert-small {
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 320px;
}
.fixed-top-alert-large {
  position: relative;
  top: -4px;
  z-index: 1;
  width: 100%;
  max-width: 480px;
}
#otp-card
  word-wrap: break-word;
.bg-light-grey
  background-color: #f5f5f5;
#edelivery_disclosure_iframe
  width: 100%;
  height: 164px;
  border: none;

#go_paperless_action
  max-width: 300px;
  zwidth: 85%;
  position: relative;


.spacing-bottom-of-doc-pb-small
  padding-bottom:184px;

.spacing-bottom-of-doc-pb-large
  zpadding-bottom: 184px;

.setBottomDivCssSmall
  position: fixed !important;
  bottom: 20px;
  left: calc(50% - 160px);
  width: 320px;
  padding: 20px 20px;
  background-color: white;
  border-top:2px solid black;


.setBottomDivCssLarge
  bottom: 80px;
  left: calc(50% - 240px);
  width: 480px;
  padding: 20px 20px;
  background-color: white;
  border-top:2px solid black;

@media only screen and (min-height: 1070px)
  .spacing-bottom-of-doc-pb-large
    padding-bottom:24px;
  .setBottomDivCssLarge
    bottom: auto;
  .spacing-bottom-of-doc-pb-small
    padding-bottom:24px;
  .setBottomDivCssSmall
    bottom: auto;
</style>
