import { LOCALES } from '../shared/constants';

export const getDefaultState = () => ({
  rootLoading: false,
  submitLoading: false,
  locale: LOCALES[0], // default
  deviceDNA: '',
  features: [],
  alerts: [],
  tasks: [],
  initialized: false,
  agents: [],
  notifications: [],
  spanishBanner: false,
  alertsAndNotifications: [],
  showVerifyPhoneWrapper: false,
  verifyPhonePageToLand: '',
  showGoPaperlessWrapper: false,
  goPaperlessType: '',
  documents: {},
  showNavigationConfirmation: false,
  navigationPathOnUserConfirmation: '',
  navigationDialogBody: '',
  meta: {
    isMaintenanceMode: false,
    recommendedAppVersion: {},
  },
  userSessionMeta: {
    /*
    x-pri-device='iPhone 14'
    x-pri-os='17.3'
    x-pri-platform='Android'
    x-pri-app='0.2.50'
    */
    sessionId: '',
    appVersion: '',
    deviceInfo: {
      deviceId: '',
      deviceModel: '',
      deviceOsVersion: '',
      devicePlatform: '',
    },
  },
});

const state = getDefaultState();

export default state;
