<i18n src='../locales.json'></i18n>

<template>
  <v-flex
    xs12
    sm6
    md6
    class="mt8 pa8"
  >
    <CardTemplate>
      <img
        slot="icon"
        class="account-img"
        src="../assets/update-comm.svg"
        alt="communications preferences"
      >
      <span
        slot="title"
        v-html="$t('L_investment_statement')"
        class="hidden-xs-only"
      />
      <span
        slot="title"
        v-html="$t('L_investment_statement_paperless_settings')"
        class="visible-xs-only"
      />
      <v-flex
        slot="content"
        sm12
        class="text-xs-center hidden-xs-only text-xs-left mt-4 mb-4"
      >
        {{ $t('L_paperless_setting') }}
      </v-flex>
      <a
        v-ada-modal
        role="button"
        slot="action"
        href="javascript: void(0)"
        id="update_paperless_trigger"
        :aria-label="$t('L_set_communication')"
      >{{ $t('L_edit') }}</a>
    </CardTemplate>
    <MyAccountDialog
      :header="$t('L_investment_statement_paperless_settings')"
      :show="showInvestmentDialog"
      :max-width="600"
      @save="save"
      @close="closeAndGoToPreviousScreen"
      :close-is-active="closeIsActive"
      :enable-save-button="enableSaveButton && !isSaveInProgress"
    >
      <v-layout
        row
        wrap
        slot="content"
      >
        <v-flex xs12>
          <p v-if="commPreference === 'MAIL'">
            {{ $t('L_with_paperless_only_selected_as_your_prefered_document_delivery') }}
            <!-- <v-tooltip
              top
              content-class="text-xs-center pa-3"
              max-width="226"
            >
              <template v-slot:activator="{ on }">
                <v-icon
                  class="font-size--16 cursor-pointer"
                  color="info"
                  v-on="on"
                >
                  help
                </v-icon>
              </template>
              <span>{{ $t('L_paperless_tooltip') }}</span>
            </v-tooltip> -->
          </p>
          <p v-else>
            {{ $t('L_if_you_select_the_paperless_option_as_your_preferred_mutual_fund') }}
          </p>
        </v-flex>
        <v-layout row wrap>
          <v-flex xs12>
            {{ $t('L_preferred_mutual_fund_account_document_delivery_preference') }}
          </v-flex>
          <v-flex xs12>
            <v-radio-group
              row
              v-model="commPreference"
              @change="changed = true"
              class="divInlineBlock"
            >
              <v-layout row wrap class="font-size--14 divInlineBlock text-xs-right">
                <div>
                  <v-radio class="mr-1"
                           :name="'radio_mail'"
                           value="MAIL"
                           :label="$t('L_mail_only')"
                  />
                </div>
                <BR />
                <div>
                  <v-radio
                    :name="'radio_digital'"
                    value="DIGITAL"
                    :label="$t('L_paperless_only')"
                  />
                </div>
              </v-layout>
            </v-radio-group>
          </v-flex>
        </v-layout>
        <v-layout row wrap v-if="commPreference === 'MAIL'">
          <v-flex xs12>
            {{ $t('L_mailed_documents_will_be_sent_to') }}
          </v-flex>
          <v-flex xs12 mb-4>
            <div v-for="(address, index) in getAddresses" :key="index" class="mt-4 mb-4">
              <div>{{ address.address1 }}</div>
              <div>{{ address.address2 }}</div>
              <div>{{ address.city }} {{ address.province }} {{ address.postalCode }}</div>
              <div class="mb-4">
                {{ address.country }}
              </div>
            </div>
          </v-flex>
          <v-flex xs12 mb-4>
            <span>{{ $t('L_if_this_address_is_incorrect_please_reach_out_to') }}</span>
          </v-flex>
        </v-layout>
        <v-layout row wrap v-else-if="commPreference === 'DIGITAL'">
          <v-flex xs12 class="pb-4">
            {{ $t('L_paperless_document_notifications_will_be_sent_to') }} <span class="link"> {{ customerProfile.email }}</span>
          </v-flex>
          <v-flex xs12 class="pb-4">
            {{ $t('L_if_you_would_like_to_update_this_email_address_you_may_do_so_via_your') }}
          </v-flex>
          <v-flex xs12 class="mb-4">
            {{ $t('L_by_selecting_paperless_only_I_agree_to_the') }}
            <span @click="openElectronicDeliveryDisclosure" class="link">{{ $t('L_electronics_delivery_disclosure') }}</span>
          </v-flex>
        </v-layout>
      </v-layout>
    </MyAccountDialog>
    <v-dialog
      v-model="isConfirmationDialogOpen"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="600"
    >
      <v-card class="update_user_mobile_modal">
        <v-card-text>
          <v-layout
            wrap
            row
            class="modal-primary-title"
          >
            <span>
              <v-icon
                :size="this.$vuetify.breakpoint.xs ? '22' : '26'"
                @click="isConfirmationDialogOpen=false"
                class="btn-close"
                color="#333333"
              >close</v-icon>
            </span>
          </v-layout>
          <h2
            class="heading_two text-xs-center pb-2"
            aria-live="assertive"
          >
            {{ $t('L_paperless_communication_changes_saved') }}
          </h2>
        <!-- <div class="fixed-top-alert">
            <Alerts context="myaccount" />
          </div> -->
        </v-card-text>
        <v-layout>
          <v-flex
            offset-md2
            md8
            offset-xs1
            xs10
            text-xs-center
          >
            <p>{{ $t('L_paperless_communication_updated') }}</p>
            <v-btn
              block
              color="primary"
              class="mb-3 mt-5 px-2"
              @click="isConfirmationDialogOpen=false"
            >
              {{ $t("L_paperless_communication_ok") }}
            </v-btn>
          </v-flex>
        </v-layout>
        <MessageDialog
          :show-dialog="dialog"
          :has-button-two="true"
          :is-persistent="true"
          type="info"
          :title="$t('L_are_you_sure_you_would_like_to_cancel')"
          :message="''"
          :button-two-label="$t('L_cancel')"
          :button-one-label="$t('L_confirm')"
          @buttonTwoAction="dialog=false;"
          @buttonOneAction="forcesave"
        >
          <template v-slot:message>
            <div class="ml-4">
              <div class="fontRegular">
                {{ $t('L_paperless_document_offers') }}
              </div>
              <div class="font-weight-bold">
                {{ $t('L_convenience') }}
              </div>
              <div class="fontRegular">
                <ul>
                  <li class="fontRegular">
                    {{ $t('L_access_any_time_any_where') }}
                  </li>
                  <li class="fontRegular">
                    {{ $t('L_print_or_archive') }}
                  </li>
                </ul>
              </div>
              <div class="font-weight-bold">
                {{ $t('L_security') }}
              </div>
              <div class="fontRegular">
                <ul>
                  <li>{{ $t('L_never_lost_in_the_mail') }}</li>
                </ul>
              </div>
            </div>
          </template>
        </MessageDialog>
      <!-- <v-layout
          wrap
          row
          class="modal-primary-title"
        >
          <span>
            <v-icon
              :size="this.$vuetify.breakpoint.xs ? '26' : '32'"
              @click="cbCloseDialog()"
              class="btn-close"
              color="#333333"
            >close</v-icon>
          </span>
        </v-layout>
        <div class="modal-content">
          <div class="modal-secondary-title">{{ $t('L_paperless_communication_changes_saved') }}</div>
          <span>{{ $t('L_paperless_communication_updated') }}</span>
        </div>
        <v-card-actions>
          <v-spacer />
          <v-btn
            v-if="this.buttonText"
            small
            color="#2196f3"
            flat
            @click="isConfirmationDialogOpen = false"
          >
            {{ $t("L_paperless_communication_ok") }}
          </v-btn>
        </v-card-actions> -->
      </v-card>
    </v-dialog>
  </v-flex>
</template>
<script>
// import Config from '@/shared/util/config';
import { mapGetters } from 'vuex';
import { openInNewWindow } from '@/mixins/openNewWindow';
import MyAccountDialog from '../MyAccountDialog.vue';
import CardTemplate from '../CardTemplate.vue';
import MessageDialog from '../../../../../components/MessageDialog.vue';
import bff from '../../../../../shared/api/bff';

export async function showDialog() {
  this.preferences = await bff.getInvestmentsCommunicationsPreferences();
  // this.commPreference = this.preferences?.investments?.channel;
  this.showInvestmentDialog = true;
  this.forceSave = false;
}

export default {
  name: 'UpdateInvestmentCommunication',
  components: {
    CardTemplate,
    MyAccountDialog,
    MessageDialog,
  },
  props: {
    invdata: {
      type: [Object],
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    showInvestmentDialog: false,
    closeIsActive: false,
    changed: false,
    headers: [
      {
        text: '',
        value: 'contractId',
      },
      {
        text: 'Online & Mail',
      },
      {
        text: 'Online Only',
      },
    ],
    selections: {},
    preferences: [],
    originalChannel: '',
    commPreference: '',
    isConfirmationDialogOpen: false,
    isSaveInProgress: false,
    forceSave: false, // Used for confirming if previously DIGITAL now selecting MAIL
  }),
  computed: {
    ...mapGetters('customer', ['customerProfile']),
    ...mapGetters('policy', ['policies']),
    ...mapGetters({
      locale: 'getLocale',
    }),
    getAddresses() {
      return this.invdata?.addresses || [];
    },
    enableSaveButton() {
      return this.commPreference !== '' && this.changed;
    },
    disclosureDocument() {
      let urlToShow = '';
      if (this.locale.indexOf('en_CA') !== -1) {
        urlToShow = 'https://assets.contentstack.io/v3/assets/blt1dedeb682e8df2a2/blt38e4792640ee26f5/ecommunication_en_CA.pdf';
      } else if (this.locale.indexOf('fr_CA') !== -1) {
        urlToShow = 'https://assets.contentstack.io/v3/assets/blt1dedeb682e8df2a2/bltf9257bbc10967b43/ecommunication_fr_CA.pdf';
      } else {
        urlToShow = 'https://assets.contentstack.io/v3/assets/blt1dedeb682e8df2a2/blt56f08e892f0cc5b0/ecommunication_en_US.pdf';
      }
      return urlToShow;
    },
  },

  mixins: [openInNewWindow],
  methods: {
    closeAndGoToPreviousScreen() {
      // This is the one that actually closes the Investment Statement Dialog
      this.dialog = false;
      this.showInvestmentDialog = false;
      this.closeIsActive = false;
    },
    async openElectronicDeliveryDisclosure() {
      let urlToShow = '';
      if (this.locale.indexOf('en_CA') !== -1) {
        urlToShow = 'https://assets.contentstack.io/v3/assets/blt1dedeb682e8df2a2/blt38e4792640ee26f5/ecommunication_en_CA.pdf';
      } else if (this.locale.indexOf('fr_CA') !== -1) {
        urlToShow = 'https://assets.contentstack.io/v3/assets/blt1dedeb682e8df2a2/bltf9257bbc10967b43/ecommunication_fr_CA.pdf';
      } else {
        urlToShow = 'https://assets.contentstack.io/v3/assets/blt1dedeb682e8df2a2/blt56f08e892f0cc5b0/ecommunication_en_US.pdf';
      }
      // const respUrl = Config.get('');
      // TODO change to correct one once we get from Business.
      const isWeb = this.$pc2capacitor.isWeb();
      await this.openInNewWindow({ url: urlToShow, target: '_blank', inappbrowser: !isWeb });
    },
    forcesave() {
      this.forceSave = true;
      this.save();
    },
    async save() {
      if (this.originalChannel === 'DIGITAL' && this.commPreference === 'MAIL' && this.forceSave === false) {
        // This is a confirmation dialog to ensure the user really want to close
        this.dialog = true;
      } else {
        try {
          this.isSaveInProgress = true;
          const reqPayloadObj = {
            investment: { channel: this.commPreference },
          // source: 'AS', // AS: Account Settings: AL: Alert from Notifications
          };

          // const changedSelections = {};
          // this.preferences.forEach((preference) => {
          //   if (this.selections[preference.policyNumber] !== preference.channel) {
          //     changedSelections[preference.policyNumber] = this.selections[preference.policyNumber];
          //   }
          // });
          /*
          * Emit a custom action when a user successfully submits an update paperless settings request
          * https://www7.v1host.com/Primerica/attachment.img/284635/Analytics%20User%20Stories%20DT_3.30.21.docx
        */
          const i = this.$dynatrace.enterAction('account_settings_changes_saved_paperless_settings');
          await bff.updateInvestmentPaperless(reqPayloadObj, this.locale);
          this.originalChannel = this.commPreference;
          this.$dynatrace.leaveAction(i);
          this.isConfirmationDialogOpen = true;
          this.showInvestmentDialog = false;
        // this.$store.commit('addAlert', { type: 'success', details: { key: 'L_update_paperless_success' }, context: 'myaccount' }, { root: true });
        } catch (e) {
          this.$store.commit('addError', { error: e, context: 'myaccount' });
        } finally {
          this.isSaveInProgress = false;
          this.closeAndGoToPreviousScreen();
        }
      }
    },
  },
  async mounted() {
    // eslint-disable-next-line no-alert
    // alert(JSON.stringify(this.invdata, 1, 3));
    //     "channel": "MAIL",
    //     "addresses": [
    //         {
    //             "address1": "123 Main Street",
    //             "address2": "Suite 100",
    //             "city": "Toronto",
    //             "province": "ON",
    //             "country": "CA",
    //             "postalCode": "M5V 1H1"
    //         },
    //         {
    //             "address1": "456 Main Street",
    //             "address2": "Suite 100",
    //             "city": "Toronto2",
    //             "province": "ON",
    //             "country": "CA",
    //             "postalCode": "M5V 2H2"
    //         }
    //     ]

    // preferences call is used to see if investment exists to show investmentCommEnabled
    const preferences = await bff.getInvestmentsCommunicationsPreferences();
    if (preferences !== undefined) {
      // TODO change to preferences.investments
      this.commPreference = preferences?.channel || '';
      this.originalChannel = this.commPreference;
    }

    const clickTarget = this.$el.querySelector('.clickable');
    if (clickTarget) {
      clickTarget.addEventListener('click', showDialog.bind(this));
    }
  },
};
</script>

<style lang="stylus" scoped="true">
.link {
  color: #02579e;
  font-weight: 500;
  cursor: pointer;
}
a[disabled="disabled"] {
  color: rgba(0, 0, 0, 0.54) !important;
  cursor: default !important;
}
.v-input--selection-controls {
 margin-top: 2px !important;
}
.policyHeader {
  width: 70px !important;
  margin-right: 20px;
}
.onlineAndMailHeader {
  width: 105px !important;
}
.onlineOnlyHeader {
  width: 105px !important;
}
.policyData {
  width: 70px !important;
  margin-right: 20px;
}
.onlineAndMailRadio {
  width: 105px !important;
}
.onlineOnlyRadio {
 width: 105px !important;
}

.divInlineBlock {
  display: inline-block;
}

.v-input {
  padding-bottom: 4px !important;
}
.backgroundWhite {
  background-color: #FFFFFF;
}
.backgroundLightGray {
  background-color: #D3D3D3;
}
.fontRegular {
  font-weight: 400;
}

</style>
