import Config from '@/shared/util/config';
import axios from 'axios';
import { sha256 } from '@/shared/util/formatters';

// * GET /alerts
const getAlerts = async () => {
  const response = await axios.get(`${Config.get('endpoints.bff')}/alerts`);
  return response;
};

// * PUT /alerts/${alertId}/dismiss
const dismissAlert = async (alertId) => {
  const response = await axios.put(`${Config.get('endpoints.bff')}/alerts/${alertId}/dismiss`);
  return response;
};

// * PUT /alerts/${alertId}/complete
const completeAlert = async (alertId, payload) => {
  const response = await axios.put(`${Config.get('endpoints.bff')}/alerts/${alertId}/complete`, payload);
  return response;
};

// * GET /documents${query}
const getDocuments = async (policyIds) => {
  const query = policyIds ? `?policyIds=${policyIds}` : '';
  const response = await axios.get(`${Config.get('endpoints.bff')}/documents${query}`);
  return response;
};

// * GET /pub/info
const getAppInfo = async () => {
  const response = await axios.get(`${Config.get('endpoints.bff')}/pub/info`);
  return response;
};

// * GET /pub/auditlog
const postAuditLog = async (auditlog) => {
  const response = await axios.post(`${Config.get('endpoints.bff')}/pub/auditlog`, auditlog);
  return response.data;
};

// * POST /policies/${policyNumber}/reinstatement
// TODO: add unit test
const postPolicyReinstatement = async (payload) => {
  const { policyNumber, reinstatementPayload } = payload;
  return axios.post(`${Config.get('endpoints.bff')}/policies/${policyNumber}/reinstatement`, reinstatementPayload);
  // return response?.data;
};

// * GET /investments/custodians
// TODO: add unit test
const searchMinorAccount = async (payload) => {
  const { taxId, dob } = payload;
  const hashTaxId = await sha256(taxId); // Convert taxId to SHA256 Hash
  const query = `type=custodian&ssnHashed=${hashTaxId?.toUpperCase()}&dob=${dob}`;
  const response = await axios.get(`${Config.get('endpoints.bff')}/investments?${query}`);
  return response?.data?.groups[0]?.accounts?.map(account =>
    ({
      accountId: account.id,
      accountNumber: account.accountNumber,
      totalMarketValue: account.endingValue,
      lastName: account.lastName,
      firstName: account.firstName,
    }));
  // TODO: remove this mock
  /* return [
    {
      accountId: '2456635',
      accountNumber: '*****1289',
      totalMarketValue: '188.0',
      lastName: 'Mcintyr',
      firstName: 'Will',
    }
  ];   */
};

// * PUT /investments/custodians/:id/link
// TODO: add unit test
const linkMinorAccount = async (accountId) => {
  // ? ex. https://gtwd.primericaonline.com/dev/pc2/bff/api/investments/custodians/2456636/link
  const linkContract = {
    contractId: `${accountId}`,
    type: 'sec',
    role: 'custodian',
  };
  const response = await axios.post(`${Config.get('endpoints.bff')}/customers/link-contracts`, linkContract);
  return response?.status === 204;
};

// Investment Account Number
const getAccountNumber = async (accountId) => {
  try {
    const response = await axios.get(`${Config.get('endpoints.bff')}/investments/${accountId}?include=numberEncrypted`);
    if (response?.status === 200 && response?.data) {
      return response.data.numberEncrypted;
    }
  } catch (e) {
  }
  return '';
};

export default {
  async bootstrap() {
    const response = await axios.get(`${Config.get('endpoints.bff')}/bootstrap`);
    return response.data;
  },

  async getInvestments(country, features) {
    const query = !features ? `?country=${country}` : `?country=${country}&features=${features}`;
    const response = await axios.get(`${Config.get('endpoints.bff')}/investments${query}`);
    return response.data;
  },

  async getCommunicationsPreferences() {
    const url = `${Config.get('endpoints.bff')}/communications/preferences`;
    const response = await axios.get(url);
    return response.data?.life;
  },

  async getInvestmentsCommunicationsPreferences() {
    // TODO uncomment all commented code
    const url = `${Config.get('endpoints.bff')}/communications/preferences`;
    const response = await axios.get(url);
    return response.data?.investment;
    // return { investments: { channel: 'MAIL' } };
  },

  async updatePaperless(selections, locale, setEcommDisplayed = false) {
    const settings = Object.keys(selections).map(key => ({
      policyNumber: key,
      channel: selections[key],
    }));
    const options = {};
    if (setEcommDisplayed) {
      options.headers = { setEcommDisplayed: 'true', preferredLang: locale };
    } else {
      options.headers = { preferredLang: locale };
    }
    const payload = {
      life: settings,
    };
    return axios.patch(`${Config.get('endpoints.bff')}/communications/preferences`, payload, options);
  },

  async updateInvestmentPaperless(payload, locale) {
    // const settings = Object.keys(selections).map(key => ({
    //   policyNumber: key,
    //   channel: selections[key],
    // }));
    // const options = {};
    // if (setEcommDisplayed) {
    //   options.headers = { setEcommDisplayed: 'true', preferredLang: locale };
    // } else {
    //   options.headers = { preferredLang: locale };
    // }
    const options = {};
    options.headers = { preferredLang: locale };

    return axios.patch(`${Config.get('endpoints.bff')}/communications/preferences`, payload, options);
  },

  async snoozeEcomm() {
    return axios.patch(`${Config.get('endpoints.bff')}/communications/snooze?type=ecomm`);
  },
  async snoozeVerifyPhone() {
    return axios.patch(`${Config.get('endpoints.bff')}/communications/snooze?type=phone`);
  },
  async getInvestmentStatementMetadata() {
    const response = await axios.get(`${Config.get('endpoints.bff')}/investments/statements`);
    return response.data;
  },

  async getInvestmentCalculator(scenario) {
    const response = await axios.post(`${Config.get('endpoints.bff')}/investments/calculator`, scenario);
    return response.data;
  },

  async getSamlToken(scenario) {
    const response = await axios.post(`${Config.get('endpoints.bff')}/investments/samltoken`, scenario);
    return response.data;
  },

  async getPolicyOverview(policyIds) {
    const query = policyIds ? `?policyIds=${policyIds}` : '';
    return axios.get(`${Config.get('endpoints.bff')}/policies${query}`);
  },

  async getDimeData() {
    return axios.get(`${Config.get('endpoints.bff')}/dime`);
  },

  async dimeCalc(dimeInput) {
    const res = await axios.put(`${Config.get('endpoints.bff')}/dime/calculation`, dimeInput);
    return res.data;
  },

  async saveDimeData(data) {
    return axios.put(`${Config.get('endpoints.bff')}/dime`, data);
  },

  async getDebt() {
    return axios.get(`${Config.get('endpoints.bff')}/debt/scenarios`);
  },

  async postDebtAndCalculate(scenario) {
    return axios.post(`${Config.get('endpoints.bff')}/debt/postdebt`, scenario);
  },

  async updateContactInfo(info) {
    return axios.put(`${Config.get('endpoints.bff')}/policies/contactInfo`, info);
  },

  updateMobileNumber(mobileInfo) {
    return axios.put(`${Config.get('endpoints.bff')}/customers/phone`, mobileInfo);
  },

  async verifyContactInfo(info) {
    return axios.put(`${Config.get('endpoints.bff')}/policies/contactInfo/verify`, info);
  },

  async sendRepEmail(email) {
    return axios.post(`${Config.get('endpoints.bff')}/contact/agent`, email);
  },

  async addChildToRider(childRider) {
    return axios.post(`${Config.get('endpoints.bff')}/policies/child/add`, childRider);
  },

  async getPaymentInfo(number) {
    return axios.get(`${Config.get('endpoints.bff')}/policies/payment`, {
      headers: {
        policyNumber: number,
      },
    });
  },

  async getBankData(data) {
    return axios.get(`${Config.get('endpoints.bff')}/policies/payment/info`, {
      headers: {
        policyNo: data.policyNo,
        routingNumber: data.routingNumber,
        country: data.country,
      },
    });
  },

  async submitAchPayment(data) {
    return axios.put(`${Config.get('endpoints.bff')}/policies/payment/submit`, data);
  },

  async updateBillingMode(data) {
    return axios.put(`${Config.get('endpoints.bff')}/policies/payment/billingmode/update`, data);
  },

  async getDraftDescription(data) {
    return axios.get(`${Config.get('endpoints.bff')}/policies/draft/description`, {
      headers: {
        paidtodate: data.paidToDate,
        selectedday: data.selectedMonthlyPaymentDay,
      },
    });
  },

  async submitEcomms(data) {
    return axios.post(`${Config.get('endpoints.bff')}/policies/ecomm/update`, data);
  },

  async pullPackages() {
    return axios.get(`${Config.get('endpoints.bff')}/crc/packages/summary`);
  },

  async pullPackage(policyId, packageId) {
    return axios.get(`${Config.get('endpoints.bff')}/crc/package/info`, {
      headers: {
        packageId,
        policyId,
      },
    });
  },

  async updatePackage(data) {
    return axios.put(`${Config.get('endpoints.bff')}/crc/package/submit`, data);
  },

  async getAgreement(type = 'U', locale, policyNumber) {
    return axios.get(`${Config.get('endpoints.bff')}/customers/agreements/get?policyNumber=${policyNumber}&locale=${locale}&type=${type}`);
  },

  async updateLanguagePreference(preferredLang) {
    return axios.patch(`${Config.get('endpoints.bff')}/customers/preferredlang`, { preferredLang });
  },

  async paymentSelection(data) {
    return axios.post(`${Config.get('endpoints.bff')}/policies/paymentselection`, data);
  },

  async updatePassword(request) {
    return axios.put(`${Config.get('endpoints.bff')}/customers/password_change`, request);
  },

  async changeUserVerification(request) {
    return axios.post(`${Config.get('endpoints.bff')}/customers/id/change-verification`, request);
  },

  async createChallenge(challengeSpec) {
    return axios.post(`${Config.get('endpoints.bff')}/customers/challenges`, challengeSpec);
  },

  async requestUserIdChallenge(request) {
    return axios.post(`${Config.get('endpoints.bff')}/customers/challenges`, request);
  },

  async updateUserID(request) {
    return axios.patch(`${Config.get('endpoints.bff')}/customers/id`, request);
  },

  async getAgents(ids) {
    const response = await axios.get(`${Config.get('endpoints.bff')}/agents/${ids.join(',')}`);
    return response.data;
  },

  async getTrustedDevices() {
    return axios.get(`${Config.get('endpoints.bff')}/devices/trusted-devices`);
  },

  async deleteTrustedDevices() {
    return axios.delete(`${Config.get('endpoints.bff')}/devices/trusted-devices`);
  },

  async ackPolicy(data) {
    return axios.patch(`${Config.get('endpoints.bff')}/policies/acknowledge`, data);
  },

  async getRightAnswersHelpUrl() {
    const rightanswerslink = Config.get('endpoints.rightanswerslink');
    const x = axios.get(rightanswerslink);
    await this.timeout(2500);
    return x;
  },

  async timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  },

  async getBeneficiaries(policyNumber) {
    return axios.get(`${Config.get('endpoints.bff')}/policies/${policyNumber}/beneficiaries`);
  },

  async setBeneficiaries(policyNumber, data) {
    return axios.put(`${Config.get('endpoints.bff')}/policies/${policyNumber}/beneficiaries`, { beneficiaries: data });
  },

  async setMonthlyWithDrawalDay(policyNumber, data) {
    return axios.put(`${Config.get('endpoints.bff')}/policies/${policyNumber}/payment/draftday`, { draftDay: data });
  },

  async updateTermsOfUse(agreementId) {
    return axios.patch(`${Config.get('endpoints.bff')}/customers/agreements/${agreementId}/version`);
  },
  getAlerts,
  dismissAlert,
  completeAlert,
  getDocuments,
  getAppInfo,
  postAuditLog,
  postPolicyReinstatement,
  searchMinorAccount,
  linkMinorAccount,
  getAccountNumber,
};
