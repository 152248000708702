<i18n src='../locales.json'></i18n>

<template>
  <v-flex
    xs12
    sm6
    md6
    class="mt8 pa8"
    v-if="validPolicies.length > 0"
  >
    <CardTemplate>
      <img
        slot="icon"
        class="account-img"
        src="../assets/update-comm.svg"
        alt="communications preferences"
      >
      <span
        slot="title"
        v-html="$t('L_set_communication')"
      />
      <v-flex
        slot="content"
        sm12
        class="text-xs-center hidden-xs-only text-xs-left mt-4 mb-4"
      >
        {{ $t('L_paperless_tile_text') }}
      </v-flex>
      <a
        v-ada-modal
        role="button"
        slot="action"
        href="javascript: void(0)"
        id="update_paperless_trigger"
        :aria-label="$t('L_set_communication')"
      >{{ $t('L_edit') }}</a>
    </CardTemplate>
    <MyAccountDialog
      :header="$t('L_set_communication')"
      :show="showUpdatePhoneDialog"
      :max-width="600"
      @save="save"
      @close="showUpdatePhoneDialog = false, closeIsActive = false"
      :close-is-active="closeIsActive"
      :enable-save-button="enableSaveButton && !isSaveInProgress"
    >
      <v-layout
        row
        wrap
        slot="content"
      >
        <v-flex xs12>
          <p>
            {{ $t('L_online_only_text') }}
            <v-tooltip
              top
              content-class="text-xs-center pa-3"
              max-width="226"
            >
              <template v-slot:activator="{ on }">
                <v-icon
                  class="font-size--16 cursor-pointer"
                  color="info"
                  v-on="on"
                >
                  help
                </v-icon>
              </template>
              <span>{{ $t('L_paperless_tooltip') }}</span>
            </v-tooltip>
          </p>
        </v-flex>
        <v-flex xs12>
          <div class="text-xs-left divInlineBlock policyHeader">
            Policy #
          </div>
          <div class="text-xs-left divInlineBlock onlineAndMailHeader">
            {{ $t('Online_&_Mail') }}
          </div>
          <div class="text-xs-left divInlineBlock onlineOnlyHeader">
            {{ $t('Online_Only') }}
          </div>
        </v-flex>
        <v-layout
          row
          wrap
          v-for="(policy, index) in validPolicies"
          :key="index"
          :class="index%2===0?'backgroundLightGray': 'backgroundWhite'"
        >
          <v-flex xs12 class="text-xs-left divInlineBlock font-size--14">
            <div class="text-xs-left divInlineBlock policyData">
              {{ policy.contractId }}
            </div>
            <v-radio-group
              row
              v-model="selections[policy.contractId]"
              @change="changed = true"
              class="divInlineBlock"
            >
              <v-layout row wrap class="font-size--14 divInlineBlock text-xs-right">
                <div class="text-xs-right divInlineBlock onlineAndMailRadio">
                  <v-radio class="mr-1"
                           :name="'radio_' + policy.contractId"
                           value="MAIL"
                  />
                </div>
                <div class="text-xs-right divInlineBlock onlineOnlyRadio">
                  <v-radio
                    :name="'radio_' + policy.contractId"
                    value="DIGITAL"
                  />
                </div>
              </v-layout>
            </v-radio-group>
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex xs12>
            {{ $t('L_paperless_footer') }}
            <p
              v-html="$t('L_paperless_disclosure', { disclosureLink: disclosureDocument })"
              class="pt-4"
            />
            <p class="pt-4 pb-4">
              {{ $t('L_paperless_notifications', { customerEmail: customerProfile.email }) }}
            </p>
          </v-flex>
        </v-layout>
      </v-layout>
    </MyAccountDialog>
    <v-dialog
      v-model="isConfirmationDialogOpen"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="600"
    >
      <v-card class="update_user_mobile_modal">
        <v-card-text>
          <v-layout
            wrap
            row
            class="modal-primary-title"
          >
            <span>
              <v-icon
                :size="this.$vuetify.breakpoint.xs ? '22' : '26'"
                @click="isConfirmationDialogOpen=false"
                class="btn-close"
                color="#333333"
              >close</v-icon>
            </span>
          </v-layout>
          <h2
            class="heading_two text-xs-center pb-2"
            aria-live="assertive"
          >
            {{ $t('L_paperless_communication_changes_saved') }}
          </h2>
        <!-- <div class="fixed-top-alert">
            <Alerts context="myaccount" />
          </div> -->
        </v-card-text>
        <v-layout>
          <v-flex
            offset-md2
            md8
            offset-xs1
            xs10
            text-xs-center
          >
            <p>{{ $t('L_paperless_communication_updated') }}</p>
            <v-btn
              block
              color="primary"
              class="mb-3 mt-5 px-2"
              @click="isConfirmationDialogOpen=false"
            >
              {{ $t("L_paperless_communication_ok") }}
            </v-btn>
          </v-flex>
        </v-layout>
      <!-- <v-layout
          wrap
          row
          class="modal-primary-title"
        >
          <span>
            <v-icon
              :size="this.$vuetify.breakpoint.xs ? '26' : '32'"
              @click="cbCloseDialog()"
              class="btn-close"
              color="#333333"
            >close</v-icon>
          </span>
        </v-layout>
        <div class="modal-content">
          <div class="modal-secondary-title">{{ $t('L_paperless_communication_changes_saved') }}</div>
          <span>{{ $t('L_paperless_communication_updated') }}</span>
        </div>
        <v-card-actions>
          <v-spacer />
          <v-btn
            v-if="this.buttonText"
            small
            color="#2196f3"
            flat
            @click="isConfirmationDialogOpen = false"
          >
            {{ $t("L_paperless_communication_ok") }}
          </v-btn>
        </v-card-actions> -->
      </v-card>
    </v-dialog>
  </v-flex>
</template>
<script>
import { mapGetters } from 'vuex';
import MyAccountDialog from '../MyAccountDialog.vue';
import CardTemplate from '../CardTemplate.vue';
import bff from '../../../../../shared/api/bff';

export async function showDialog() {
  if (this.validPolicies.length === 0) {
    return;
  }
  this.preferences = await bff.getCommunicationsPreferences();
  this.preferences.forEach((preference) => {
    this.selections[preference.policyNumber] = preference.channel;
  });
  this.showUpdatePhoneDialog = true;
}

export default {
  name: 'UpdateCommunication',
  components: {
    CardTemplate,
    MyAccountDialog,
  },
  data: () => ({
    showUpdatePhoneDialog: false,
    closeIsActive: false,
    changed: false,
    headers: [
      {
        text: '',
        value: 'contractId',
      },
      {
        text: 'Online & Mail',
      },
      {
        text: 'Online Only',
      },
    ],
    selections: {},
    preferences: [],
    isConfirmationDialogOpen: false,
    isSaveInProgress: false,
  }),
  computed: {
    ...mapGetters('customer', ['customerProfile']),
    ...mapGetters('policy', ['policies']),
    ...mapGetters({
      locale: 'getLocale',
    }),
    enableSaveButton() {
      let enable = true;
      Object.keys(this.selections).forEach((key) => {
        if (this.selections[key] !== 'DIGITAL' && this.selections[key] !== 'MAIL') {
          enable = false;
        }
      });
      return enable && this.changed;
    },
    validPolicies() {
      return this.policies.filter(policy => +policy.contractId !== 0 && policy.access !== 'FULL');
    },
    disclosureDocument() {
      let urlToShow = '';
      if (this.locale.indexOf('en_CA') !== -1) {
        urlToShow = 'https://assets.contentstack.io/v3/assets/blt1dedeb682e8df2a2/blt38e4792640ee26f5/ecommunication_en_CA.pdf';
      } else if (this.locale.indexOf('fr_CA') !== -1) {
        urlToShow = 'https://assets.contentstack.io/v3/assets/blt1dedeb682e8df2a2/bltf9257bbc10967b43/ecommunication_fr_CA.pdf';
      } else {
        urlToShow = 'https://assets.contentstack.io/v3/assets/blt1dedeb682e8df2a2/blt56f08e892f0cc5b0/ecommunication_en_US.pdf';
      }
      return urlToShow;
    },
  },
  methods: {
    async save() {
      try {
        this.isSaveInProgress = true;
        const changedSelections = {};
        this.preferences.forEach((preference) => {
          if (this.selections[preference.policyNumber] !== preference.channel) {
            changedSelections[preference.policyNumber] = this.selections[preference.policyNumber];
          }
        });
        /*
          * Emit a custom action when a user successfully submits an update paperless settings request
          * https://www7.v1host.com/Primerica/attachment.img/284635/Analytics%20User%20Stories%20DT_3.30.21.docx
        */
        const i = this.$dynatrace.enterAction('account_settings_changes_saved_paperless_settings');
        await bff.updatePaperless(changedSelections, this.locale);
        this.$dynatrace.leaveAction(i);
        this.isConfirmationDialogOpen = true;
        this.showUpdatePhoneDialog = false;
        // this.$store.commit('addAlert', { type: 'success', details: { key: 'L_update_paperless_success' }, context: 'myaccount' }, { root: true });
      } catch (e) {
        this.$store.commit('addError', { error: e, context: 'myaccount' });
      } finally {
        this.isSaveInProgress = false;
      }
    },
  },
  mounted() {
    const clickTarget = this.$el.querySelector('.clickable');
    if (clickTarget) {
      clickTarget.addEventListener('click', showDialog.bind(this));
    }
  },
};
</script>

<style lang="stylus" scoped="true">
a[disabled="disabled"] {
  color: rgba(0, 0, 0, 0.54) !important;
  cursor: default !important;
}
.v-input--selection-controls {
 margin-top: 2px !important;
}
.policyHeader {
  width: 70px !important;
  margin-right: 20px;
}
.onlineAndMailHeader {
  width: 105px !important;
}
.onlineOnlyHeader {
  width: 105px !important;
}
.policyData {
  width: 70px !important;
  margin-right: 20px;
}
.onlineAndMailRadio {
  width: 105px !important;
}
.onlineOnlyRadio {
 width: 105px !important;
}

.divInlineBlock {
  display: inline-block;
}

.v-input {
  padding-bottom: 4px !important;
}
.backgroundWhite {
  background-color: #FFFFFF;
}
.backgroundLightGray {
  background-color: #D3D3D3;
}

</style>
