<i18n src="./locales.json"></i18n>

<template>
  <v-card class="pa-3 add_child_modal">
    <v-card-title class="pa-0">
      <v-layout justify-end>
        <a href="javascript: void(0)" role="button" aria-label="Close" class="add_child_close" @click="($v.$anyDirty && (cancelDialog = true)) || closeAddChild()">
          <v-icon>close</v-icon>
        </a>
      </v-layout>
    </v-card-title>
    <Alert
      :class="{
        'full-width': $vuetify.breakpoint.smAndUp,
      }"
      :show-alert="addChildError"
      :dismissable="false"
      :auto-dismiss="true"
      :message="$t(addChildErrorMessage)"
      @close-alert="(addChildError = false), (addchildErrorCode = undefined)"
    />
    <v-card-text>
      <v-layout justify-center row wrap>
        <v-flex xs12 md6 class="text-xs-center">
          <h2 aria-live="assertive" class="font-weight-regular">
            {{ $t('L_add_child_dialog_title') }}
          </h2>
          <h4 class="mt-3 font-weight-regular">
            {{ $t('L_add_child_dialog_text') }}
          </h4>
        </v-flex>
        <v-flex xs12 md8 mt-3>
          <v-divider />
        </v-flex>
        <v-flex xs12 md6>
          <v-layout row wrap>
            <v-flex xs12 mt-4 pt-3>
              <FirstNameInput v-model="firstName" :form-input="$v.firstName" input-id="first_name" :label="$t('L_firstname')" :auto-capitalize="true" />
            </v-flex>
            <v-flex xs12>
              <FirstNameInput
                input-id="middle_name"
                v-model="middleName"
                :form-input="$v.middleName"
                :label="$t('L_middle_name')"
                :validation-messages="{
                  name: 'Middle Name is Invalid.',
                  maxLength: 'Middle Name can be a Maximum of 32 Characters in Length.',
                }"
                :auto-capitalize="true"
              />
            </v-flex>
            <v-flex xs12>
              <LastNameInput input-id="last_name" v-model="lastName" :form-input="$v.lastName" :label="$t('L_last_name')" :auto-capitalize="true" />
            </v-flex>
            <v-flex xs12>
              <DateInput
                id="birth_date"
                v-model="birthDate"
                :form-input="$v.birthDate"
                :label="$t('L_birth_date')"
                :quebec="isFrenchLocale"
                :validation-messages="{
                  date: $t('L_invalid_date'),
                  minDate: (this.currentPolicy.nextGenPolicy ? $t('L_child_too_old_next_gen'):$t('L_child_too_old')),
                  dateLessThan15DayOldOrInFuture: $t('L_child_too_young'),
                  required: $t('L_birth_date_required'),
                  bornOnOrAfterIssue: $t('L_born_after_issued'),
                }"
              />
            </v-flex>
            <v-flex v-if="isUsPolicy" xs12>
              <SSNInput id="ssn" v-model="ssn" enable-masking :form-input="$v.ssn" :label="$t('L_ssn')" @focus="showTooltip = false" @blur="showTooltip = false" />
            </v-flex>
            <v-flex v-if="isCanadianPolicy" xs12>
              <SINInput id="sin" v-model="sin" enable-masking :form-input="$v.sin" :label="$t('L_sin')" @focus="showTooltip = false" @blur="showTooltip = false" />
            </v-flex>
            <SSNTooltip :activator="isCanadianPolicy ? '#sin' : '#ssn'" :value="showTooltip" />

            <v-flex xs12 pb-4 mb-3>
              <span class="font-weight-regular font-size--18">{{ $t('L_relationship_to_the_primary_insured') }}</span>
              <RadioButtonGroup id="gender" v-model="gender" :options="genders" :form-input="$v.gender" />
            </v-flex>

            <v-flex xs12 pb-4 mb-3>
              <span class="font-weight-regular font-size--18">{{ $t('L_does_the_child_reside_with') }}</span>
              <RadioButtonGroup id="resideWith" v-model="resideWith" :options="resideWithOptions" :form-input="$v.resideWith" />
            </v-flex>
            <v-flex xs12>
              <v-btn block color="primary" :disabled="invalidFields" @click.prevent="submit">
                {{ $t('L_add_child') }}
              </v-btn>
            </v-flex>
            <v-flex xs12>
              <v-btn
                v-ada-modal
                id="add_child_cancel_trigger"
                block
                color="default"
                aria-label="Close"
                class="add_child_close"
                @click.prevent="($v.$anyDirty && (cancelDialog = true)) || closeAddChild()"
              >
                {{ $t('L_cancel') }}
              </v-btn>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-card-text>
    <v-dialog v-model="cancelDialog" max-width="380">
      <v-card class="add_child_cancel_modal">
        <v-card-title>
          <v-layout align-center class="headline font-weight-medium warning--text">
            <v-icon class="warning-icon warning--text mr-3">
              warning
            </v-icon>
            <span aria-live="assertive">{{ $t('L_are_you_sure_title') }}</span>
            <v-spacer />
            <a class="add_child_cancel_close" role="button" href="javascript: void(0)" @click="cancelDialog = false">
              <v-icon>close</v-icon>
            </a>
          </v-layout>
        </v-card-title>
        <v-card-text>
          <span>{{ $t('L_are_you_sure_text') }}</span>
          <br>
          <span>{{ $t('L_data_will_be_lost') }}</span>
        </v-card-text>
        <v-card-actions>
          <v-layout justify-end>
            <v-btn class="blue--text" small flat @click="(cancelDialog = false), closeAddChild()">
              {{ $t('L_yes_button') }}
            </v-btn>
            <v-btn class="blue--text add_child_cancel_close" small flat @click="cancelDialog = false">
              {{ $t('L_no_button') }}
            </v-btn>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import {
  FirstNameInput,
  FirstNameValidation,
  LastNameInput,
  LastNameValidation,
  DateInput,
  DateValidation,
  SSNInput,
  SSNValidation,
  SINInput,
  SINValidation,
  RadioButtonGroup,
} from '@/components/Inputs';
import Alert from '@/components/Alert/Alert.vue';
import { required } from 'vuelidate/lib/validators';
import { mapGetters, mapActions } from 'vuex';
import {
  // minDate,
  minDateNonNextGen,
  minDateNextGen,
  // maxDate,
  dateLessThan15DayOldOrInFuture,
  bornOnOrAfterIssue,
  formatFromQuebec,
  formatFromUS,
} from './dateValidation';
import SSNTooltip from './components/SSNTooltip.vue';

export function errCallback(err) {
  if (err?.response?.data?.errorCode) {
    this.addChildErrorCode = err.response.data.errorCode;
  }
  this.addChildError = true;
  this.$el.parentElement.scrollTop = 0;
}

export default {
  name: 'AddChild',
  components: {
    SSNTooltip,
    FirstNameInput,
    LastNameInput,
    DateInput,
    SSNInput,
    SINInput,
    Alert,
    RadioButtonGroup,
  },
  data: () => ({
    firstName: '',
    middleName: '',
    lastName: '',
    birthDate: '',
    gender: '',
    resideWith: 'Y',
    sin: '',
    ssn: '',
    addChildError: false,
    addChildErrorCode: undefined,
    showTooltip: false,
    cancelDialog: false,
  }),
  validations() {
    return {
      firstName: {
        ...FirstNameValidation,
        required,
      },
      middleName: {
        ...FirstNameValidation,
      },
      lastName: {
        ...LastNameValidation,
        required,
      },
      birthDate: {
        date: DateValidation.date(false, this.isFrenchLocale),
        // We are using MM/DD/YYYY for Canada and US except for Quebec DD/MM/YYYY
        // so we can pass false flag for canada
        minDate: this.currentPolicy?.nextGenPolicy ? minDateNextGen(false, this.isFrenchLocale) : minDateNonNextGen(false, this.isFrenchLocale),
        // minDate: minDate(false, this.isFrenchLocale),
        // maxDate: maxDate(false, this.isFrenchLocale),
        dateLessThan15DayOldOrInFuture: dateLessThan15DayOldOrInFuture(false, this.isFrenchLocale),
        bornOnOrAfterIssue: bornOnOrAfterIssue(this.issueDateForCurrentPolicy, false, this.isFrenchLocale),
        required,
      },
      gender: {
        required,
      },
      sin: {
        ...SINValidation,
      },
      ssn: {
        ...SSNValidation,
      },
      resideWith: {
        required,
      },
    };
  },
  computed: {
    ...mapGetters('policy', {
      currentPolicy: 'currentPolicy',
      issueDateForCurrentPolicy: 'issueDateForCurrentPolicy',
    }),
    ...mapGetters({
      locale: 'getLocale',
    }),
    invalidFields() {
      if (this.isUsPolicy) {
        // Validation will handle other checks
        return this.ssn.length === 0 || this.firstName.length === 0 || this.lastName.length === 0 ||
        this.birthDate.length === 0 || this.gender.length === 0 || this.resideWith.length === 0 || this.$v.$anyError;
      } else if (this.isCanadianPolicy) {
        // Validation will handle other checks
        return this.sin.length === 0 || this.firstName.length === 0 || this.lastName.length === 0 ||
        this.birthDate.length === 0 || this.gender.length === 0 || this.resideWith.length === 0 || this.$v.$anyError;
      } return true;
    },
    formattedBirthDate() {
      switch (true) {
        case this.isFrenchLocale:
          return formatFromQuebec(this.birthDate);
        default:
          return formatFromUS(this.birthDate);
      }
    },
    isUsPolicy() {
      return !!(this.currentPolicy?.isUSPolicy || this.currentPolicy?.isNewYorkPolicy);
    },
    isCanadianPolicy() {
      return !!this.currentPolicy?.isCanadaPolicy;
    },
    isQuebecPolicy() {
      return this.currentPolicy?.isCanadaQuebecPolicy;
    },
    isFrenchLocale() {
      if (this.locale?.length > 0) {
        if (this.locale.toLowerCase().indexOf('fr') > -1) {
          return true;
        }
        return false;
      }
      return false;
    },
    addChildErrorMessage() {
      switch (this.addChildErrorCode) {
        case 4001:
          return 'L_err_child_exists';
        case 4002:
          return 'L_err_child_birth_date_before_issue';
        case 4003:
          return 'L_err_child_less_than_15_days';
        case 4004:
          return 'L_err_child_older_than_24_5';
        case 4005:
          return 'L_err_child_max_9';
        default:
          break;
      }
      return 'L_add_child_error';
    },
    genders() {
      return [
        {
          label: this.$t('L_son'),
          value: 'M',
        },
        {
          label: this.$t('L_daughter'),
          value: 'F',
        },
      ];
    },
    resideWithOptions() {
      return [
        {
          label: this.$t('L_yes_button'),
          value: 'Y',
        },
        {
          label: this.$t('L_no_button'),
          value: 'N',
        },
      ];
    },
  },
  methods: {
    ...mapActions('policy', {
      addChild: 'addChildRider',
      setToReady: 'setToReady',
    }),
    resetForm() {
      this.firstName = '';
      this.lastName = '';
      this.middleName = '';
      this.gender = '';
      this.sin = '';
      this.ssn = '';
      this.resideWith = 'Y';
      this.birthDate = '';
      this.$v.$reset();
    },
    closeAddChild(success = null) {
      /*
       * Emit a custom action when a user successfully submits an add a child request
       * https://www7.v1host.com/Primerica/attachment.img/284635/Analytics%20User%20Stories%20DT_3.30.21.docx
       */
      this.resetForm();
      if (success) {
        const i = this.$dynatrace.enterAction('add_child_successful_submission');
        this.$dynatrace.leaveAction(i);
        this.$emit('close');
      } else {
        this.$emit('cancel');
      }
    },
    submit() {
      const child = {
        childFirstName: this.firstName,
        childMiddleName: this.middleName,
        childLastName: this.lastName,
        childGender: this.gender,
        childSSN: this.ssn || this.sin,
        childBirthDate: this.formattedBirthDate,
        childRelationship: this.gender === 'F' ? 'D' : 'S',
        residentWithParent: this.resideWith,
      };
      this.addChild({
        policyNumber: this.currentPolicy.contractId,
        child,
      })
        .then(() => this.closeAddChild(true))
        .catch(errCallback.bind(this));
      // If a user came from the CRC's "Add Another Child" option,
      // we need to clear the step to ready so that it won't open
      // add a child modal to pop up when they come back to the Policy Details page.
      this.setToReady();
    },
  },
};
</script>

<style lang="stylus">
#relationship
  width 100%
  button
    width 50%
.full-width
  margin 0 -16px 0 -16px
.warning-icon
  font-size 32px
</style>
