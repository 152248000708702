import bff from '@/shared/api/bff';

// TODO: add unit test
export const postPolicyReinstatement = async (context, payload) => {
  try {
    const response = await bff.postPolicyReinstatement(payload);
    if (response.status === 200 || response.status === 201) {
      return response?.data?.redirectUrl || '';
    } else {
      throw new Error('Failure with reinstatement submission.');
    }
  } catch (error) {
    throw error;
  }
};
