<i18n src="./locales.json"></i18n>
<template>
  <v-container>
    <v-layout row wrap class="main-body" justify-center>
      <v-flex xs12>
        <v-layout pt-2 pb-4>
          <div>
            <v-icon large color="#b4c9db">
              folder_open
            </v-icon>
          </div>
          <v-flex shrink>
            <span class="pl-3 font-weight-regular font-size--24 white--text" role="heading" aria-level="1">{{ metadata.title }}</span>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-card class="mb-4">
      <v-layout row v-if="routeName !== 'NoticesAndConfirmations'">
        <v-flex xs12>
          <v-card tile flat class="pa-2" id="documents-note-text">
            <v-card-text v-if="isCanadian && routeName === 'InvestmentStatements'">
              {{ $t('L_view_statements_for') }}
              <v-flex xs4>
                <v-select
                  class="pb-0"
                  v-model="viewInvestmentStatementsFilter"
                  :items="viewInvestmentStatementsFilterItems"
                />
              </v-flex>
              {{ $t('L_please_note_that_some_documents') }}
            </v-card-text>
            <v-card-text v-else>
              <!-- Life Insurance -->
              {{ $t('L_please_note_that_some_documents') }}
              <div class="mt-4 font-weight-bold">
                {{ $t('L_if_you_do_not_see_a_copy_of_your_full_life_policy_it') }}
              </div>
            </v-card-text>
            <v-card-text v-if="isCanadian && routeName === 'InvestmentStatements'" class="font-weight-bold">
              {{ $t('L_only_mutual_fund_account_statements_are_available_to_view_on_myprimerica_for_common_sense') }} <a href="javascript:void(0)" @click="agfLink()">www.agf.com</a>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs12 v-if="routeName === 'NoticesAndConfirmations'">
          <v-card tile flat class="pa-2" id="documents-note-text">
            <v-card-text id="please-note-verbiage">
              {{ $t('L_view_documents_for_last') }}
              <v-flex xs4>
                <v-select
                  class="pb-0"
                  v-model="viewNoticesAndConfirmationsFilter"
                  :items="viewNoticesAndConfirmationsFilterItems"
                />
              </v-flex>
              {{ $t('L_please_note_that_some_documents') }}
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs12>
          <v-card v-if="metadata.documentList.length > 0">
            <v-list two-line>
              <template v-for="(item, index) in metadata.documentList">
                <v-list-tile :key="item.name+''+index" avatar ripple @click="getDocumentPDF(item)">
                  <v-list-tile-content>
                    <v-list-tile-title id="document-title">
                      {{ item.name }}
                    </v-list-tile-title>
                    <v-list-tile-sub-title class="d-flex">
                      <span>{{ item.date }}</span><span>{{ item.type }}</span>
                    </v-list-tile-sub-title>
                  </v-list-tile-content>

                  <v-list-tile-action>
                    <v-icon id="right-arrow" color="#02579e" large>
                      keyboard_arrow_right
                    </v-icon>
                  </v-list-tile-action>
                </v-list-tile>
                <v-divider v-if="index + 1 < metadata.documentList.length" :key="index" />
              </template>
            </v-list>
          </v-card>
          <v-card v-else>
            <v-card-text>
              <div>
                <img id="documents-folder-img" height="180" width="180" src="@/assets/documentsFolder.png" alt="documents-folder" class="d-flex mx-auto">
              </div>
              <div id="you-have-no-available" class="font-weight-bold text-xs-center">
                {{ $t('L_no_documents_currently_available') }}
              </div>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-card>
  </v-container>
</template>

<script>
import { COUNTRIES } from '@/shared/constants';
import { openInNewWindow } from '@/mixins/openNewWindow';
import { mapGetters, mapActions } from 'vuex';
import { formatDate } from '@/shared/util/formatters';
import moment from 'moment';
import dayjs from 'dayjs';

export default {
  name: 'DocumentList',
  components: {},
  mixins: [openInNewWindow],
  props: {
    routeName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      documentsLoaded: true,
      loading: true,
      viewNoticesAndConfirmationsFilter: 30,
      viewInvestmentStatementsFilter: (new Date()).getFullYear(),
    };
  },
  computed: {
    ...mapGetters(['documents', 'policyDocuments', 'noticesAndConfirmations', 'investmentStatements']),
    ...mapGetters('policy', ['base64String']),
    ...mapGetters({
      dateFormat: 'getDateFormat',
      currentLocale: 'getLocale',
    }),
    ...mapGetters('customer', ['binaryInvestmentStatement', 'customerProfile']),
    isCanadian() {
      return this.customerProfile?.country === COUNTRIES.CANADA;
    },
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    viewNoticesAndConfirmationsFilterItems() {
      return [
        { text: this.$t('L_1_month'), value: 30 },
        { text: this.$t('L_3_months'), value: 90 },
        { text: this.$t('L_6_months'), value: 180 },
        { text: this.$t('L_all'), value: 0 },
      ];
    },
    viewInvestmentStatementsFilterItems() {
      const currentYear = (new Date()).getFullYear();
      const items = [{ text: currentYear.toString(), value: currentYear }];
      for (let yr = 1; yr < 7; yr++) {
        items.push({ text: (currentYear - yr), value: currentYear - yr });
      }
      items.push({ text: this.$t('L_all'), value: 0 });
      return items;
    },
    filteredNoticesAndConfirmations() {
      const cutoffDate = dayjs().subtract(+this.viewNoticesAndConfirmationsFilter, 'day');
      const filteredDocumentsList = this.noticesAndConfirmations.filter(((doc) => {
        const isWithinCutoff = dayjs(doc.dateReceived).isAfter(cutoffDate) ||
          dayjs(doc.dateReceived).isSame(cutoffDate);
        return isWithinCutoff;
      }));
      return this.viewNoticesAndConfirmationsFilter === 0 ? this.noticesAndConfirmations : filteredDocumentsList;
    },
    filteredInvestmentStatements() {
      const cutoffDate = dayjs(new Date(this.viewInvestmentStatementsFilter, 0, 1));
      const filteredDocumentsList = this.investmentStatements.filter(((doc) => {
        const isWithinCutoff = dayjs(doc.dateReceived).isSame(cutoffDate, 'year');
        return isWithinCutoff;
      }));
      return this.viewInvestmentStatementsFilter === 0 ? this.investmentStatements : filteredDocumentsList;
    },
    metadata() {
      const m = {};
      const df = this.dateFormat;
      if (this.routeName === 'PolicyDocuments') {
        m.title = this.$t('L_policy_documents');
        const d = this.policyDocuments;
        const r = d.map(i => ({
          date: formatDate(moment(i.dateReceived, 'YYYY-MM-DD'), df),
          name: `${i.labels.filter(label => label.language === this.currentLocale)[0].name} - ${i.contract}`,
          type: i.labels.filter(label => label.language === this.currentLocale)[0].type,
          doc: i,
        }));
        m.documentList = r;
      } else if (this.routeName === 'NoticesAndConfirmations') {
        m.title = this.$t('L_notices_and_confirmations');
        const d = this.filteredNoticesAndConfirmations; // this is a filtered this.noticesAndConfirmations;
        const r = d.map(i => ({
          date: formatDate(moment(i.dateReceived, 'YYYY-MM-DD'), df),
          name: `${i.labels.filter(label => label.language === this.currentLocale)[0].name} - ${i.contract}`,
          type: i.labels.filter(label => label.language === this.currentLocale)[0].type,
          doc: i,
        }));
        m.documentList = r;
      } else if (this.routeName === 'InvestmentStatements') {
        m.title = this.$t('L_investment_statements');
        const d = this.filteredInvestmentStatements;
        const r = d.map((i) => {
          const startMonth = this.$t(`L_${i.name.period.start.month.toLowerCase()}`);
          const endMonth = this.$t(`L_${i.name.period.end.month.toLowerCase()}`);
          const name = `${startMonth} ${i.name.period.start.day} - ${endMonth} ${i.name.period.end.day}, ${i.name.period.year}`;
          return {
            date: formatDate(moment(i.dateReceived, 'YYYY-MM-DD'), df),
            name,
            type: `${this.$t(`L_${i.type}`)}`,
            doc: i,
          };
        });
        m.documentList = r;
      }
      return m;
    },
  },
  created() {},
  watch: {
    isMobile(newVal, oldVal) {
      if (newVal !== oldVal) {
        if (!this.isMobile && this.$route.name === 'DocumentListMobile') {
          // redirect to mobile view;
          this.$router.push({ name: this.routeName || 'NoticesAndConfirmations' });
        }
      }
    },
  },
  methods: {
    ...mapActions('customer', ['openStatement']),
    // ...mapActions(['getDocuments']),
    async getDocumentPDF(item) {
      try {
        const s = item?.doc?.name;
        const t = `view_doc_${s[0].toLowerCase()}${s.slice(1).replace(/ /g, '')}`;
        const i = this.$dynatrace.enterAction(t);
        this.$dynatrace.leaveAction(i);
      } catch (error) { /*  */ }

      if (this.routeName === 'PolicyDocuments' || this.routeName === 'NoticesAndConfirmations') {
        let url = '';
        if (item?.doc?.documentType === 'A') {
          url = await this.getAllDocumentsUrl(item?.doc?.metadata);
        } else {
          url = await this.getSingleDocumentUrl(item?.doc?.metadata);
        }
        const isWeb = this.$pc2capacitor.isWeb();
        await this.openInNewWindow({ url, inappbrowser: !isWeb });
      } else if (this.routeName === 'InvestmentStatements') {
        const isWeb = this.$pc2capacitor.isWeb();
        const url = await this.getInvestmentDocument(item?.doc?.metadata);
        await this.openInNewWindow({ url, inappbrowser: !isWeb });
      }
    },
    async agfLink() {
      await this.openInNewWindow({ url: 'http://www.agf.com/', target: '_blank' });
    },
    // ***
    getInvestmentDocument(requestObject) {
      const isWeb = this.$pc2capacitor.isWeb();
      if (isWeb) {
        return this.openStatement(requestObject).then(() => URL.createObjectURL(new Blob([this.binaryInvestmentStatement], { type: 'application/pdf' })));
      } else {
        return this.openStatement(requestObject).then(() => this.binaryInvestmentStatement);
      }
    },
    // ***
    getSingleDocumentUrl(requestObject) {
      const isWeb = this.$pc2capacitor.isWeb();
      if (isWeb) {
        return this.$store.dispatch('policy/getOnePolicyDocument', requestObject).then(() => URL.createObjectURL(new Blob([this.base64String], { type: 'application/pdf' })));
      } else {
        return this.$store.dispatch('policy/getOnePolicyDocument', requestObject).then(() => this.base64String);
      }
    },
    // ***
    getAllDocumentsUrl(requestObject) {
      const isWeb = this.$pc2capacitor.isWeb();
      if (isWeb) {
        return this.$store.dispatch('policy/getAllPolicyDocuments', requestObject).then(() => URL.createObjectURL(new Blob([this.base64String], { type: 'application/pdf' })));
      } else {
        return this.$store.dispatch('policy/getAllPolicyDocuments', requestObject).then(() => this.base64String);
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    // called before the route that renders this component is confirmed.
    // does NOT have access to `this` component instance,
    // because it has not been created yet when this guard is called!
    next((vm) => {
      // access to component public instance via `vm`
      try {
        const i = vm.$dynatrace.enterAction('view_documentsPage');
        vm.$dynatrace.leaveAction(i);
      } catch (error) { /*  */ }
    });
  },
  beforeRouteUpdate() {
    // called when the route that renders this component has changed,
    // but this component is reused in the new route.
    try {
      const i = this.$dynatrace.enterAction('view_documentsPage');
      this.$dynatrace.leaveAction(i);
    } catch (error) { /*  */ }
  },
};
</script>

<style scoped lang="stylus">
div#document-title.v-list__tile__title
  font-size: 14px !important;
  font-weight: 600 !important;
.link
  font-weight: 600;
  color: #0c6dca;
  cursor: pointer;
.myNotifications
  padding-left: 16px;
  padding-right: 16px;
.bgAllOfYourAvailable
  background: #f9f9f9;
.contactYourRepButtonWidth
  width: 264px;
  max-width: 265px;
  min-width: 264px;
.picture
  .agentImage
  .icon
    width: 55px;
    height: 55px;
    margin: 0 auto;
    border-radius: 50%;
.rep
    padding: 15px 0;
    background: #eee;
    .button-wrapper .v-btn
      width: 85%
      margin: 0 auto;
      background-color: #063562 !important;
.backgroundColorBlue
  background-color: #f5f7fa;
.backgroundColorWhite
  background-clor: #ffffff;
.borderLeftWall
  border-left: 1px solid #ccc;
.paddingForNoDismiss
  padding-right: 32px;
#you-have-no-available
  line-height: 1.5;
  text-align: center;
  color: #333;
#documents-folder-img
  margin-top:18px;
  margin-bottom:18px;
#documents-note-text
  border-bottom: solid 1px #ccc;
  padding: 0px !important;
</style>

