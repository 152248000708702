export class Reinstatement {
    _paymentAsOfReIssueDate = '';
    _paymentAsOfOrgIssueDate = '';
    _termDate = '';
    _pacPremium = '';
    _eligible = null;
    _eligibleDod = false;
    _redirectUrl = '';

    constructor({
      paymentAsOfReIssueDate,
      paymentAsOfOrgIssueDate,
      termDate,
      pacPremium,
      eligible,
      redirectUrl,
      eligibleDod,
    }) {
      this._paymentAsOfReIssueDate = paymentAsOfReIssueDate;
      this._paymentAsOfOrgIssueDate = paymentAsOfOrgIssueDate;
      this._termDate = termDate;
      this._pacPremium = pacPremium;
      this._eligible = eligible;
      this._redirectUrl = redirectUrl;
      this._eligibleDod = eligibleDod;
    }

    get paymentAsOfReIssueDate() {
      return this._paymentAsOfReIssueDate || '';
    }

    get paymentAsOfOrgIssueDate() {
      return this._paymentAsOfOrgIssueDate || '';
    }
    get termDate() {
      return this._termDate || '';
    }
    get pacPremium() {
      return this._pacPremium || '';
    }
    get eligible() {
      return this._eligible || '';
    }
    get eligibleDod() {
      return this._eligibleDod || '';
    }
    get redirectUrl() {
      return this._redirectUrl || '';
    }
}
