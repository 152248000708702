<i18n src="./locales.json"></i18n>
<template>
  <v-container>
    <v-layout row wrap class="main-body" justify-center>
      <v-flex xs12>
        <v-layout pt-2 pb-4>
          <div>
            <v-icon large color="#FFFFFF">
              notifications
            </v-icon>
          </div>
          <v-flex shrink>
            <span class="pl-3 font-weight-regular font-size--24 white--text" role="heading" aria-level="1">{{ $t('L_my_message_center') }}</span>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-card class="mb-4">
      <v-card-text>
        <v-flex class="myNotifications">
          <v-layout row align-start>
            <v-flex>
              <v-layout row wrap>
                <v-flex xs12 font-size--24>
                  {{ $t('L_my_notifications') }}
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-card-text>
      <v-card-text class="bgAllOfYourAvailable">
        <v-flex pa-3>
          <v-layout row align-start>
            <v-flex>
              <v-layout row wrap>
                <v-flex xs12 mb-6 ml-6 mr-6>
                  {{ $t('L_all_of_your_available') }}
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-card-text>
      <v-card-text v-if="loading">
        <v-flex pa-3>
          <v-layout>
            <v-flex>
              <LoadingIndicator :loading="!alertsAndNotificationsLoaded" />
            </v-flex>
          </v-layout>
        </v-flex>
      </v-card-text>
      <div>
        <span v-for="(item, index) in alertsAndNotifications" :key="index + 'alertAndNotify'">
          <v-card-text :class="index % 2 == 0 ? 'backgroundColorBlue' : 'backgroundColorWhite'" font-size--14>
            <v-layout row wrap>
              <v-flex xs1 sm1 md1 lg1 mb-6 ml-6 mr-6 text-xs-center>
                <v-icon v-if="item.alertIcon && item.alertIcon.toLowerCase() === 'warning'" color="orange">
                  warning
                </v-icon>
                <v-icon v-else-if="item.alertIcon && item.alertIcon.toLowerCase() === 'info'" color="blue">
                  info
                </v-icon>
                <v-icon v-else-if="item.alertIcon && item.alertIcon.toLowerCase() === 'error'" color="red">
                  error
                </v-icon>
              </v-flex>
              <v-flex xs10 sm10 md10 lg10 mb-6 ml-6 mr-6>
                {{ $t(item.messages.filter(message => message.language === getLocale)[0].message) }}
                <a @click="viewNotification(item)" class="pr-2">
                  {{ item.messages.filter(message => message.language === getLocale)[0].actionText }}
                </a>
              </v-flex>
              <v-flex xs1 sm1 lg1 md1 mb-6 ml-6 mr-6 text-xs-right>
                <span v-show="item.allowDismiss">
                  <a class="pl-2" href="javascript:void(0)" @click="dismissAction(item.alertId)"><v-icon x-small color="#333333"> close </v-icon></a>
                </span>
                <span v-show="!item.allowDismiss" class="pl-2 paddingForNoDismiss">
                  &nbsp;
                </span>
              </v-flex>
            </v-layout>
          </v-card-text>
        </span>
      </div>
      <v-card-text v-if="!alertsAndNotifications || alertsAndNotifications.length === 0">
        <div class="py-10">
          <img
            id="notification-bell-img"
            height="180"
            width="180"
            src="@/assets/notificationBell.png"
            alt="notification bell"
            class="d-flex mx-auto"
          >
        </div>
        <div id="you-have-no-available" class="font-weight-bold">
          {{ $t('L_you_have_no_available_notifications') }}
        </div>
      </v-card-text>
    </v-card>
    <NotificationDetail
      v-if="showNotificationDetailDialog"
      :show-notification-detail="showNotificationDetailDialog"
      :notification-id="notificationDetailId"
      @accept-action-clicked="acceptActionClickedOnNotificationDetail"
      @reject-action-clicked="rejectActionClickedOnNotificationDetail"
      @dismiss-action-clicked="dismissActionClickedOnNotificationDetail"
    />
    <VerifyPhoneWrapper
      :allow-edit="true"
      :show="showVerifyPhoneWrapper"
      @show-successful-verify="handleCallsGetAlerts"
      @close-verify-phone-dialog="closeVerifyPhoneDialog"
      @gotoupdatecontact2="goToUpdateContact"
      ref="verifyacc"
    />
    <SuccessVerifyPhone v-if="showSuccessfulVerify" @close-success-verify-phone="closeSuccessVerifyPhone" />
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import NotificationDetail from '@/components/MyNotifications/NotificationDetail.vue';
import VerifyPhoneWrapper from '../../components/VerifyPhoneWrapper/VerifyPhoneWrapper.vue';
import SuccessVerifyPhone from '../home/myaccount/components/successVerifyPhone/successVerifyPhone.vue';

export default {
  name: 'AlertAndNotification',
  components: {
    LoadingIndicator,
    NotificationDetail,
    VerifyPhoneWrapper,
    SuccessVerifyPhone,
  },
  data() {
    return {
      alertsAndNotificationsLoaded: true,
      loading: true,
      showNotificationDetailDialog: false,
      notificationDetailId: null,
      showVerifyPhoneWrapper: false,
      showSuccessfulVerify: false,
    };
  },
  computed: {
    ...mapGetters(['getLocale']),
    ...mapGetters(['alertsAndNotifications']),
  },
  async created() {
    if (this.alertsAndNotifications.length <= 0) {
      await this.getAlerts();
      this.loading = false;
    }
  },
  methods: {
    ...mapActions(['getAlerts', 'dismissAlert', 'completeAlert']),
    ...mapMutations(['setShowGoPaperlessWrapper', 'setGoPaperlessType', 'setShowVerifyPhoneWrapper', 'setVerifyPhonePageToLand']),
    ...mapMutations(['setVerifyPhonePageToLand']),
    handleCallsGetAlerts() {
      this.showVerifyPhoneWrapper = false;
      this.getAlerts();
      this.showSuccessfulVerify = true;
    },
    closeVerifyPhoneDialog() {
      this.showVerifyPhoneWrapper = false;
      this.getAlerts();
      this.showSuccessfulVerify = false;
    },
    goToUpdateContact() {
      this.showVerifyPhoneWrapper = false;
      this.$refs.updateContactRef.showUpdatePhoneDialog = true;
    },
    closeSuccessVerifyPhone() {
      this.showSuccessfulVerify = false;
    },
    viewNotification(alertObj) {
      if (alertObj) {
        if (alertObj.actionType === 'PHONE_VERIFICATION') {
          this.showVerifyPhoneWrapper = true;
          this.setVerifyPhonePageToLand('first');
        } else if (alertObj.actionType === 'PAPERLESS_COMM') {
          this.setShowGoPaperlessWrapper(true);
          this.setGoPaperlessType('PAPERLESS_COMM');
        } else if (alertObj.actionType === 'PAPERLESS_COMM_INVESTMENT') {
          this.setShowGoPaperlessWrapper(true);
          this.setGoPaperlessType('PAPERLESS_COMM_INVESTMENT');
        } else if (alertObj.actionType === 'ACKNOWLEDGEMENT') {
          this.showNotificationDetail(alertObj.alertId);
        } else if (alertObj.actionType === 'PRIVACY_POLICY') {
          // TODO what should we do
        } else {
          // TODO what should be default action
        }
      }
    },
    showNotificationDetail(alertId) {
      this.showNotificationDetailDialog = true;
      this.notificationDetailId = alertId;
    },
    hideNotificationDetailDialog() {
      this.showNotificationDetailDialog = false;
      this.notificationDetailId = null;
    },
    async acceptActionClickedOnNotificationDetail() {
      // await this.completeAction(this.notificationDetailId, true);
      this.hideNotificationDetailDialog();
      await this.getAlerts();
    },
    async rejectActionClickedOnNotificationDetail() {
      // await this.completeAction(this.notificationDetailId, false);
      this.hideNotificationDetailDialog();
      await this.getAlerts();
    },
    async dismissActionClickedOnNotificationDetail() {
      // await this.dismissAction(this.notificationDetailId);
      this.hideNotificationDetailDialog();
      await this.getAlerts();
    },
    async dismissAction(alertId) {
      await this.dismissAlert(alertId);
      await this.getAlerts();
    },
  },
};
</script>

<style scoped lang="stylus">
.link
  font-weight: 600;
  color: #0c6dca;
  cursor: pointer;
.myNotifications
  padding-left: 16px;
  padding-right: 16px;
.bgAllOfYourAvailable
  background: #f9f9f9;
.contactYourRepButtonWidth
  width: 264px;
  max-width: 265px;
  min-width: 264px;
.picture
  .agentImage
  .icon
    width: 55px;
    height: 55px;
    margin: 0 auto;
    border-radius: 50%;
.rep
    padding: 15px 0;
    background: #eee;
    .button-wrapper .v-btn
      width: 85%
      margin: 0 auto;
      background-color: #063562 !important;
.backgroundColorBlue
  background-color: #f5f7fa;
.backgroundColorWhite
  background-clor: #ffffff;
.borderLeftWall
  border-left: 1px solid #ccc;
.paddingForNoDismiss
  padding-right: 32px;
#you-have-no-available
  line-height: 1.5;
  text-align: center;
  color: #333;
#notification-bell-img
  margin-top:18px;
  margin-bottom:18px;
</style>
