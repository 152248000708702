<i18n src="./locales.json"></i18n>
<template>
  <v-card class="marginAboveTerminatedPolicy">
    <v-layout row wrap>
      <!-- Policy Number and status -->
      <v-flex xs12 sm6 pl-4 :class="$vuetify.breakpoint.smAndUp?'pt-4':'pt-2'" class="border_bottom_line">
        <v-icon
          class="right-icon font-size--28"
          color="#063562"
        >
          description
        </v-icon>
        <span class="font-size--16">
          {{ $t('L_policy_number') }}:
        </span>
        <span class="font-size--16 font-weight-bold">
          {{ policyData.contractId }}
        </span>
      </v-flex>
      <v-flex xs12 sm6 text-xs-right pr-4 pb-2 :class="$vuetify.breakpoint.smAndUp?'pt-4':'pt-1 pb-2'" class="border_bottom_line">
        <span class="text-xs-right">
          <span>{{ $t('L_status') }}</span>
          <span class="ml-3">
            <v-icon
              class="policy-status-icon"
              :color="policyStatusIconColor"
            >
              {{ policyStatusIcon }} <!-- {{ selectedPolicy.pending ? 'access_time' : 'check_circle' }} -->
            </v-icon>
            <span class="font-size--14">
              {{ $t('L_terminated_application') }} <!-- {{ selectedPolicy.pending ? $t('L_active_application') : $t('L_active') }} -->
            </span>
          </span>
        </span>
      </v-flex>
    </v-layout>
    <!-- Reinstatement body -->
    <v-flex xs12 align-self-center v-if="isTerminatedAndEligibleForReinstatement">
      <div v-if="isEligibleForFirstTimeReinstatement && !reinstatementRedirectUrlExists">
        <Message
          type="error"
          :button-label="$t('L_reinstate_policy')"
          button-color="primary"
          :message-header="$t('L_term_life_policy_terminated_header')"
          :message="$t('L_term_life_policy_terminated_message')"
          @click="navigateToReinstatement"
          message-type="base"
        />
      </div>
      <div v-else-if="reinstatementRedirectUrlExists">
        <Message
          type="error"
          :button-label="$t('L_complete_and_submit')"
          button-color="primary"
          :message-header="$t('L_you_have_a_reinstatement_application_in_progress')"
          :message="$t('')"
          @click="goToTheReinstatementUrl"
          message-type="base"
        />
      </div>
      <div v-else-if="isNotEligibleReinstatmentInProgress && !reinstatementRedirectUrlExists" class="ml-4 mr-4">
        <!-- Code here for the new image -->
        <v-stepper v-if="$vuetify.breakpoint.xsOnly" vertical value="3" class="font-size--16">
          <v-stepper-step step="1" complete>
            {{ $t('L_Submitted') }}
          </v-stepper-step>
          <v-stepper-step step="2" complete>
            {{ $t('L_Received') }}
          </v-stepper-step>
          <v-stepper-step step="3">
            {{ $t('L_In_Review') }}
          </v-stepper-step>
          <v-stepper-content step="3">
            <div class="font-size--16 font-weight-bold mt-4 ml-4">
              {{ $t('L_your_policy_reinstament_request_is_currently_in_review') }}
            </div>
            <div class="font-size--14 font-weight-regular ml-4 mb-4 pb-4">
              <i18n path="L_please_allow_PLACEHOLDER" tag="p" class="font-weight-regular">
                <span>{{ customerServicePhoneNum }}</span>
              </i18n>
            </div>
          </v-stepper-content>
        </v-stepper>
        <v-stepper v-else value="3" class="font-size--16">
          <v-stepper-header>
            <v-stepper-step step="1" complete>
              {{ $t('L_Submitted') }}
            </v-stepper-step>
            <v-stepper-step step="2" complete>
              {{ $t('L_Received') }}
            </v-stepper-step>
            <v-stepper-step step="3">
              {{ $t('L_In_Review') }}
            </v-stepper-step>
          </v-stepper-header>
          <div class="font-size--16 font-weight-bold mt-4 ml-4">
            {{ $t('L_your_policy_reinstament_request_is_currently_in_review') }}
          </div>
          <div class="font-size--14 font-weight-regular ml-4 mb-4 pb-4">
            <i18n path="L_please_allow_PLACEHOLDER" tag="p" class="font-weight-regular">
              <span>{{ customerServicePhoneNum }}</span>
            </i18n>
          </div>
        </v-stepper>
      </div>
    </v-flex>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { openInNewWindow } from '@/mixins/openNewWindow';
import Alert from '@/components/Alert.vue';
import Representative from '@/components/Representative.vue';
import Message from '@/components/Message.vue';

export default {
  name: 'TerminatedPolicy',
  components: {
    Alert,
    Representative,
    Message,
  },
  mixins: [openInNewWindow],
  data: () => ({
    status: false,
    showPayInProgressTooltip: false,
  }),
  props: {
    policyData: {
      type: Object,
      required: true,
      default: () => { },
    },
  },
  computed: {
    ...mapGetters('policy', {
      policies: 'policies',
      loading: 'policyInfoLoading',
      terminatedPolicyAgent: 'terminatedPolicyAgent',
    }),
    ...mapGetters({ getLocale: 'getLocale' }),
    customerServicePhoneNum() {
      if (this.getLocale === 'en_US') {
        return '(800) 257-4725';
      } else if (this.getLocale === 'en_CA') {
        return '(800) 387-7876';
      } else if (this.getLocale === 'fr_CA') {
        return '(800) 265-4804';
      }
      return '(800) 257-4725';
    },
    isTerminatedAndEligibleForReinstatement() {
      return !!this.policyData?._reinstatement;
    },
    isEligibleForReinstatement() {
      // 1st step - Start the reinstatement Process
      // Apply to reinstate your coverage
      return this.policyData?._reinstatement && this.policyData?._reinstatement?._eligible === true;
    },
    isNotEligibleReinstatmentInProgress() { // isProccessedForReinstatement
      return this.policyData?._reinstatement?._eligible === false || false;
    },
    isEligibleForFirstTimeReinstatement() {
      return this.policyData?._reinstatement?._eligible === true || false;
    },
    reinstatementRedirectUrlExists() {
      return this.policyData?._reinstatement?._redirectUrl !== undefined;
    },
    reinstatementRedirectUrl() {
      return this.policyData?._reinstatement?._redirectUrl;
    },
    policyList() {
      return this.policies.map(p => ({ contractId: p.contractId }));
    },
    policyStatusIconColor() {
      return 'error';
    },
    policyStatusIcon() {
      return 'error';
    },
  },
  methods: {
    async navigateToReinstatement() {
      try {
        // track in dynatrace
        const i = this.$dynatrace.enterAction('Reinstatement_StartAction');
        this.$dynatrace.leaveAction(i);
        const tempIndex = this.policies.findIndex(policy => policy.contractId === this.policyData.contractId);
        this.$store.commit('policy/setSelectedPolicyIndex', tempIndex);
        // track in cp activity log
        await this.$store.dispatch('postAuditLog', { actionId: 'Reinstatement_StartAction' });
      } catch (error) { /*  */ }
      await this.$router.push({ name: 'PolicyReinstatement' });
    },
    async goToTheReinstatementUrl() {
      const isWeb = this.$pc2capacitor.isWeb();
      await this.openInNewWindow({ url: this.reinstatementRedirectUrl, target: '_blank', inappbrowser: !isWeb });
    },
  },
};
</script>

<style lang="stylus" scoped>
.marginAboveTerminatedPolicy
  margin-top: 30px;

</style>
